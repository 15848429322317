// Libraries
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import axios from "axios";

@Module({
  name: "esyoilstore",
  namespaced: true,
  stateFactory: true,
})
export default class EsyoilStore extends VuexModule {
  esyoilRestApi = {
    authentication: {
      orderToken: "",
    },
  };
  publicPartnerRatings = [];
  publicEsyoilRatings = [];
  companyRating = {
    ratingCount: 0,
    reviewCount: 0,
    ratingValue: 0,
  };
  pricePredictionVoting = {
    userHasVoted: true,
  };
  userPriceTrendVoting = {
    votingQuantity: "",
    votingDate: "",
    positiveVotesPercentage: 0,
    negativeVotesPercentage: 0,
    previousVoteData: {
      votingQuantity: "",
      votingDate: "",
      positiveVotesPercentage: 0,
      negativeVotesPercentage: 0,
    },
  };
  esyoilRatingSended = null;
  newsletterSignupStatus = false;
  priceNotificationSignupStatus = false;
  errorMessage = "";
  pwErrorMessage = "";
  nlErrorMessage = "";
  dealerOffersData = {
    errorMessage: "",
    dealerOffers: [],
  };

  isEsyoilAccess = false;
  preisanteile = {
    data: [
      { percentage: 11, description: "Deckungsbeiträge" },
      { percentage: 6, description: "Logistikanteil" },
      { percentage: 21, description: "Steuer- und Abgabenanteil" },
      { percentage: 62, description: "Produktanteil" },
    ],
    dateFrom: "2022-11-25",
  };

  @Mutation
  setErrorMessage(message) {
    this.errorMessage = message;
  }

  @Mutation
  setPwErrorMessage(message) {
    this.pwErrorMessage = message;
  }

  @Mutation
  setNlErrorMessage(message) {
    this.nlErrorMessage = message;
  }

  @Mutation
  setUserVotingEligibility(votingStatus: boolean) {
    this.pricePredictionVoting.userHasVoted = votingStatus;
  }

  @Mutation
  setEsyoilRatingData(data) {
    this.companyRating = {
      ratingCount: data.ratingCount,
      reviewCount: data.reviewCount,
      ratingValue: data.ratingValue,
    };
  }

  @Mutation
  setUserPriceTrendVotingData(data) {
    this.userPriceTrendVoting.votingQuantity = data.count;
    this.userPriceTrendVoting.votingDate = data.date;
    this.userPriceTrendVoting.positiveVotesPercentage = data.positive;
    this.userPriceTrendVoting.negativeVotesPercentage = data.negative;
  }

  @Mutation
  setPreviousUserPriceTrendVotingData(data) {
    this.userPriceTrendVoting.previousVoteData.votingQuantity = data.count;
    this.userPriceTrendVoting.previousVoteData.votingDate = data.date;
    this.userPriceTrendVoting.previousVoteData.positiveVotesPercentage =
      data.positive;
    this.userPriceTrendVoting.previousVoteData.negativeVotesPercentage =
      data.negative;
  }

  @Mutation
  setNewsletterSignupState(data) {
    this.newsletterSignupStatus = data;
  }

  @Mutation
  clearPublicPartnerRatings() {
    this.publicPartnerRatings = [];
  }

  @Mutation
  pushPublicPartnerRatings(data: Array<object>) {
    data.forEach((partnerRatingDataset) => {
      this.publicPartnerRatings.push(partnerRatingDataset);
    });
  }

  @Mutation
  clearPublicEsyoilRatings() {
    this.publicEsyoilRatings = [];
  }

  @Mutation
  pushPublicEsyoilRatings(data: Array<object>) {
    data.forEach((partnerRatingDataset) => {
      this.publicEsyoilRatings.push(partnerRatingDataset);
    });
  }

  @Mutation
  setPriceNotificationSignupStatus(data) {
    this.priceNotificationSignupStatus = data;
  }

  @Mutation
  setEsyoilRatingSuccess(status: boolean) {
    this.esyoilRatingSended = status;
  }

  @Action
  async sendEsyoilRatingData(ratingData: {
    serviceID: number;
    rating: number;
    comment: string;
  }) {
    const params = new URLSearchParams();
    params.append("dealId", ratingData.serviceID.toString());
    params.append("ratingValue", ratingData.rating.toString());
    params.append("ratingBody", ratingData.comment);
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    try {
      await axios.post(
        `${process.env.API_ESYOIL}${process.env.POST_ESYOIL_RATINGS}`,
        params,
        config,
      );
      this.context.commit("setEsyoilRatingSuccess", true);
    } catch (err) {
      this.context.commit("setEsyoilRatingSuccess", false);
    }
  }

  @Action
  async checkUserVotingPermission(articleID) {
    try {
      const result = await axios.get(
        `${process.env.API_ESYOIL}${process.env.USER_PRICE_VOTING}/${articleID}/check`,
      );
      this.context.commit(
        "setUserVotingEligibility",
        result.data.data.has_voted,
      );
    } catch (error) {
      this.context.commit("setErrorMessage", error);
    }
  }

  @Action
  async postPricePredictionVoting(votingData: {
    id: string;
    voteBooleanNumber: string;
  }) {
    try {
      const result = await axios.get(
        `${process.env.API_ESYOIL}${process.env.USER_PRICE_VOTING}/${votingData.id}/vote/${votingData.voteBooleanNumber}`,
      );
      this.context.dispatch("fetchUserPriceTrendVoting", votingData.id);
      this.context.commit("setUserVotingEligibility", 1);
    } catch (error) {
      console.error(error);
      this.context.commit("setErrorMessage", error);
    }
  }

  @Action
  async fetchUserPriceTrendVoting(newsID: number) {
    try {
      const result = await axios.get(
        `${process.env.API_ESYOIL}${process.env.USER_PRICE_VOTING}/${newsID}/get`,
      );
      this.context.commit("setUserPriceTrendVotingData", result.data.data);
      this.context.dispatch("fetchLastUserVotingNewsID", newsID);
    } catch (error) {
      //
    }
  }

  @Action
  async fetchLastUserVotingNewsID(newsID: number) {
    try {
      const result = await axios.get(
        `${process.env.API_ESYOIL}${process.env.USER_PRICE_VOTING}/${newsID}/last`,
      );
      this.context.dispatch(
        "fetchPreviousUserPriceTrendVoting",
        result.data.data[1].new_id,
      );
    } catch (error) {
      console.warn("Error", error);
    }
  }

  @Action
  async fetchPreviousUserPriceTrendVoting(newsID: number) {
    try {
      const result = await axios.get(
        `${process.env.API_ESYOIL}${process.env.USER_PRICE_VOTING}/${newsID}/get`,
      );
      this.context.commit(
        "setPreviousUserPriceTrendVotingData",
        result.data.data,
      );
    } catch (error) {
      console.warn("Error", error);
    }
  }

  @Action
  async fetchEsyoilRatingData() {
    try {
      const result = await axios.get(
        `${process.env.API_ESYOIL}${process.env.ESYOIL_RATING}`,
      );
      this.context.commit("setEsyoilRatingData", result.data.data);
      return true;
    } catch (error) {
      // do not handle this exception
    }
  }

  @Action
  async fetchPublicPartnerRatings(data: { limit: number; offset: number }) {
    try {
      const result = await axios.get(
        `${process.env.API_ESYOIL}${process.env.PUBLIC_PARTNER_RATINGS}/${data.limit}/${data.offset}`,
      );
      this.context.commit("pushPublicPartnerRatings", result.data.data);
    } catch (error) {
      console.error(error);
      this.context.commit("setErrorMessage", error);
    }
  }

  @Action
  async fetchPublicEsyoilRatings(data: { limit: number; offset: number }) {
    try {
      const result = await axios.get(
        `${process.env.API_ESYOIL}${process.env.ORDER_RATINGS}/${data.limit}/${data.offset}`,
      );
      this.context.commit("pushPublicEsyoilRatings", result.data.data);
    } catch (error) {
      console.error(error);
      this.context.commit("setErrorMessage", error);
    }
  }

  @Action
  async postNewsletterSubscription(requestBody: {
    email: string;
    zipcode: string;
  }) {
    const params = new URLSearchParams();
    params.append("email", requestBody.email);
    params.append("zipcode", requestBody.zipcode);
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    try {
      const result = await axios.post(
        `${process.env.API_ESYOIL}${process.env.NEWSLETTER_SUBSCRIPTION}`,
        params,
        config,
      );
      this.context.commit("setNewsletterSignupState", !result.data.errors);

      if (result.data.errors) {
        this.context.commit("setNlErrorMessage", result.data.errors[0].message);
      } else {
        this.context.commit("setNlErrorMessage", "");
      }
    } catch (error) {
      console.warn("from esyoil.ts >> fetchGasStationData", error);
    }
  }

  @Action
  async postPriceNotification(requestBody: {
    price: string;
    amount: string;
    mail: string;
    zip: string;
    unloading_points: string;
    origin: string | undefined;
  }) {
    const params = new URLSearchParams();
    params.append("price", requestBody.price);
    params.append("amount", requestBody.amount);
    params.append("email", requestBody.mail);
    params.append("zipcode", requestBody.zip);
    params.append("ups", requestBody.unloading_points);
    params.append("origin", requestBody.origin);
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    try {
      const result = await axios.post(
        `${process.env.API_ESYOIL}${process.env.PRICE_NOTIFICATION_SUBSCRIPTION}`,
        params,
        config,
      );

      if (result.data.errors) {
        this.context.commit("setPwErrorMessage", result.data.errors[0].message);
        return false;
      } else {
        this.context.commit("setPwErrorMessage", "");
        return true;
      }
    } catch (error) {
      console.error("Preiswecker signup error", error);
    }
  }

  buyEPeilstabInvoice = {
    success: false,
  };
  @Mutation
  postEPeilstabCheckoutSuccess(value: boolean) {
    this.buyEPeilstabInvoice.success = value;
  }
  @Action
  async postEPeilstabCheckout(formData) {
    try {
      const result = await axios({
        method: "post",
        url: `${process.env.API_ESYOIL}${process.env.ORDER_E_PEILSTAB}`,
        headers: {
          accept: "*/*",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: {
          company: formData.companyName,
          title: formData.salutation,
          firstname: formData.firstName,
          lastname: formData.lastName,
          street: formData.streetName,
          streetnum: formData.houseNumber,
          zipcode: formData.zipcode,
          city: formData.city,
          phone: formData.phone,
          email: formData.eMail,
          message: formData.additionalMessage,
          voucher: "",
          "g-recaptcha-response": formData.gRecaptchaResponse,
        },
      });
      this.context.commit("postEPeilstabCheckoutSuccess", true);
    } catch (error) {
      //
    }
  }

  @Mutation
  setIsEsyoilAccess(data: boolean) {
    this.isEsyoilAccess = data;
  }

  @Action
  async dispatchIsEsyoilAccessLoading() {
    try {
      const result = await axios.get(
        `${process.env.API_ESYOIL}/v1/security/isesyoil`,
      );
      this.context.commit("setIsEsyoilAccess", result.data.data.isAllowed);
    } catch (e) {
      console.error(e);
    }
  }

  @Mutation
  setPreisanteile(data) {
    this.preisanteile = data;
  }

  @Action
  async fetchPreisanteile() {
    try {
      const result = await axios.get(
        `${process.env.PARTNER_MEDIA_CDN}/assets/preisanteile.json`,
      );
      this.context.commit("setPreisanteile", result.data);
    } catch (err) {
      console.log(err);
    }
  }
}

// Libraries
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import axios from "axios";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import "dayjs/locale/de";
dayjs.extend(isoWeek);
dayjs.locale("de");

@Module({
  name: "blog",
  namespaced: true,
  stateFactory: true,
})
export default class Blog extends VuexModule {
  newestPostID = null;
  cmsRestAPI = {
    postQuantity: 20,
    loadingQuantity: 0,
    totalLoadingQuantity: 0,
    currentRequestCategoryID: 1,
  };
  date = {
    today: dayjs().format("DD.MM.YYYY"),
    calendarWeek: {
      dayOfStart: dayjs().isoWeekday(1).format("DD"),
      dateOfEnd: dayjs().isoWeekday(5).format("DD.MM.YYYY"),
      weekNumber: dayjs().isoWeek(),
    },
  };
  articles = [];
  crossLinks = [];
  authors = [];
  singleBlogPost: any = 0;
  errorMessage = "";
  employees = [];
  textSnippets = [];
  singleTextSnippet = {};

  public get postQuantity(): string {
    return this.cmsRestAPI.postQuantity.toString();
  }

  public get loadingQuantity(): string {
    return this.cmsRestAPI.loadingQuantity.toString();
  }

  @Mutation
  setErrorMessage(errorMessage) {
    this.articles = errorMessage;
  }

  @Mutation
  pushBlogPosts(data: { payload; isSingleBlogPost: boolean }) {
    data.payload.forEach((element) => {
      element.additionalParameters = {
        date: {
          iso: dayjs(element.date).toISOString(),
          postDate: dayjs(element.date).format("DD.MM.YYYY"),
          dd: dayjs(element.date).format("DD"),
          dddd: dayjs(element.date).format("dddd"),
          mm: dayjs(element.date).format("MM"),
          mmmm: dayjs(element.date).format("MMMM"),
          yyyy: dayjs(element.date).format("YYYY"),
        },
      };

      if (data.isSingleBlogPost) {
        this.singleBlogPost = element;
      } else {
        this.articles.push(element);
      }
    });
  }

  @Mutation
  setAuthorsData(
    authorData: Array<{ id: number; name: string; avatar_urls: {} }>,
  ) {
    if (this.authors === authorData) return;
    this.authors = authorData;
  }

  @Mutation
  setEmployeesData(employeesData: Array<{ id: number; name: string }>) {
    this.employees = employeesData;
  }

  @Mutation
  setTextSnippetData(data: Array<{ id: number }>) {
    this.textSnippets = data;
  }

  @Mutation
  initSingleTextSnippet(payload) {
    this.singleTextSnippet = payload;
  }

  @Mutation
  setTotalLoadingInstances(payload) {
    this.cmsRestAPI.totalLoadingQuantity = payload;
  }

  @Mutation
  setCategoryId(ID) {
    this.cmsRestAPI.currentRequestCategoryID = ID;
  }

  @Mutation
  initLoadingQuantity() {
    this.cmsRestAPI.loadingQuantity = 1;
  }

  @Mutation
  setNewestPostID(id: number) {
    this.newestPostID = id;
  }

  @Mutation
  countUpLoadingQuantity() {
    this.cmsRestAPI.loadingQuantity += 1;
  }

  @Mutation
  clearArticlesArray() {
    this.articles = [];
  }

  @Mutation
  clearSingleBlogPost() {
    this.singleBlogPost = 0;
  }

  @Mutation
  setCrossLinkArticles(payload) {
    this.crossLinks = payload;
  }

  @Action
  async fetchSingleData(data: { payload; type: string }) {
    const setup = {
      textSnippet: {
        mutation: "initSingleTextSnippet",
        url: `${process.env.WORDPRESS_SINGLE_TEXT_SNIPPET}`,
      },
    };

    try {
      const result = await axios.get(
        `${process.env.MAIN_WORDPRESS}${setup[data.type].url}?id=${data.payload
        }`,
      );
      this.context.commit(setup[data.type].mutation, result.data);
    } catch (error) {
      this.context.commit("setErrorMessage", error);
    }
  }

  @Action
  async fetchEmployeesData() {
    try {
      const result = await axios.get(
        `${process.env.MAIN_WORDPRESS}${process.env.WORDPRESS_EMPLOYEES}`,
      );
      this.context.commit("setEmployeesData", result.data);
    } catch (error) {
      this.context.commit("setErrorMessage", error);
    }
  }

  @Action
  async fetchTextSnippetData() {
    try {
      const result = await axios.get(
        `${process.env.MAIN_WORDPRESS}${process.env.WORDPRESS_TEXT_SNIPPETS}`,
      );
      this.context.commit("setTextSnippetData", result.data);
    } catch (error) {
      this.context.commit("setErrorMessage", error);
    }
  }

  @Action
  async fetchAuthorsData() {
    try {
      const result = await axios.get(
        `${process.env.MAIN_WORDPRESS}${process.env.WORDPRESS_USERS}`,
      );
      this.context.commit("setAuthorsData", result.data);
    } catch (error) {
      this.context.commit("setErrorMessage", error);
    }
  }

  @Action
  async fetchDateRangeBlogPosts(dateRange: {
    startDateTime: string;
    endDateTime: string;
  }) {
    try {
      this.context.commit("clearArticlesArray");
      const result = await axios.get(
        `${process.env.MAIN_WORDPRESS}${process.env.WORDPRESS_POSTS}?orderby=date&order=desc&after=${dateRange.startDateTime}&before=${dateRange.endDateTime}&per_page=50`,
      );
      this.context.commit("pushBlogPosts", {
        payload: result.data,
        isSingleBlogPost: false,
      });
    } catch (error) {
      this.context.commit("setErrorMessage", error);
    }
  }

  @Action
  async fetchBlogPosts(request) {
    if (request.initial) {
      this.context.commit("clearArticlesArray");
      this.context.commit("initLoadingQuantity");
    } else {
      this.context.commit("countUpLoadingQuantity");
    }

    try {
      const urlAddition = request.ID ? `&categories=${request.ID}` : "";
      const result = await axios.get(
        `${process.env.MAIN_WORDPRESS}${process.env.WORDPRESS_POSTS}?per_page=${this.context.getters.postQuantity}&page=${this.context.getters.loadingQuantity}${urlAddition}`,
      );

      this.context.commit("pushBlogPosts", {
        payload: result.data,
        isSingleBlogPost: false,
      });

      if (request.initial) {
        this.context.commit(
          "setTotalLoadingInstances",
          result.headers["x-wp-totalpages"],
        );
      }
    } catch (error) {
      this.context.commit("setErrorMessage", error);
    }
  }

  @Action
  async fetchNewestBlogPosts() {
    try {
      this.context.commit("clearArticlesArray");
      const result = await axios.get(
        `${process.env.MAIN_WORDPRESS}${process.env.WORDPRESS_POSTS}?per_page=1`,
      );
      this.context.commit("pushBlogPosts", {
        payload: result.data,
        isSingleBlogPost: false,
      });
      this.context.commit("setNewestPostID", result.data[0].id);
    } catch (error) {
      this.context.commit("setErrorMessage", error);
    }
  }

  @Action
  async fetchNewestBlogPostId() {
    try {
      const result = await axios.get(
        `${process.env.MAIN_WORDPRESS}${process.env.WORDPRESS_POSTS}?per_page=1`,
      );
      this.context.commit("setNewestPostID", result.data[0].id);
    } catch (error) {
      this.context.commit("setErrorMessage", error);
    }
  }

  @Action
  async fetchSingleBlogPost(ID) {
    this.context.commit("clearArticlesArray");
    this.context.commit("clearSingleBlogPost");
    try {
      const result = await axios.get(
        `${process.env.MAIN_WORDPRESS}${process.env.WORDPRESS_POSTS}/${ID}`,
      );
      this.context.commit("pushBlogPosts", {
        payload: [result.data],
        isSingleBlogPost: true,
      });
    } catch (e) {
      this.context.commit("setErrorMessage", e);
    }
  }

  @Action
  async fetchSingleBlogPostCrossLinks() {
    try {
      const originalDate = dayjs(this.singleBlogPost.date);
      const formattedDateStr = originalDate
        .utc()
        .format("YYYY-MM-DD[T]00:00:00[Z]");

      const result = await axios.get(
        `${process.env.MAIN_WORDPRESS}${process.env.WORDPRESS_POSTS}?_fields=slug,link,title,date,id&per_page=8&before=${formattedDateStr}&orderby=date&order=desc`,
      );

      this.context.commit("setCrossLinkArticles", result.data);
    } catch (e) {
      this.context.commit("setErrorMessage", e);
    }
  }
}

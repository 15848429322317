import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import Cookie from "js-cookie";
import { AuthenticationApi, UserActionApi } from "@esyoil-gmbh/esyapi-wrapper";
import { getMessage } from "~/plugins/error-handler";
import axios from "axios";
@Module({
  name: "user",
  namespaced: true,
  stateFactory: true,
})
export default class User extends VuexModule {
  authToken: string | null = null;
  userData: Record<string, any> | false = false;
  userDeliveryAddresses: Record<string, any>[] | false = false;

  loginError: string | false = false;

  autofill = true;

  @Action
  public async nuxtInit() {
    const auth = Cookie.get("mkAuth");

    if (auth) {
      this.context.commit("setAuthToken", auth);
      await Promise.all([this.context.dispatch("getUserData")]);
    }
  }

  @Action
  public async getUserData() {
    const a = new AuthenticationApi(undefined, process.env.API_ESYOIL);
    try {
      const res: any =
        await a.esyoilAPIEntryPointsAuthenticationInformationAboutMe(
          this.context.getters.authHeader,
        );
      if (res.data.errors) {
        throw res.data.errors;
      }
      this.context.commit("setUserData", res.data.data);
      this.context.dispatch("loadDeliveryAddresses");
    } catch (error) {
      this.context.dispatch("logout");
    }
  }

  @Action
  async loadDeliveryAddresses() {
    const a = new UserActionApi(undefined, process.env.API_ESYOIL);

    const res: any = await a.esyoilAPIEntryPointsUserActionGetDeliverAddresses(
      this.context.getters.authHeader,
    );

    if (res.data.errors) {
      throw res.data.errors;
    }
    this.context.commit("setDeliveryAddresses", res.data.data || []);
  }

  @Action
  public async login(userInfo: { email: string; password: string }) {
    this.context.commit("setLoginError", false);
    const a = new AuthenticationApi(undefined, process.env.API_ESYOIL);
    try {
      const res: any = await a.esyoilAPIEntryPointsAuthenticationLogin(
        userInfo.email,
        userInfo.password,
        "esyoil",
      );
      if (res.data.errors) {
        throw res.data.errors;
      }
      const authToken = res.data.data.token;
      this.context.commit("setAuthToken", authToken);

      if (res.data.data.token) {
        // login to old mk to get session token
        // build formdata
        const fd = new FormData();
        fd.append("user", userInfo.email);
        fd.append("pass", userInfo.password);
        fd.append("action", "login");
        fd.append("stay-logged-in", "1"); // to get a 1 Year cookie
        axios
          .post(`${process.env.MAIN_ESYOIL}/ajax-not-logged-in.php`, fd, {
            withCredentials: true,
          })
          .then(async (result) => {
            //
            await this.context.dispatch("nuxtInit");
          })
          .catch((error) => {
            this.context.commit("global/setErrorMessage", error);
          });
      }

      // temp commented out because old login also sets the cookies
      // Cookie.set("mkAuth", authToken, {
      //   domain: process.env.BASE_DOMAIN,
      // });
    } catch (error) {
      const message = getMessage(error, this.context, false);
      this.context.commit("setLoginError", message[0]);
    }
  }

  @Mutation
  public setLoginError(message: any) {
    this.loginError = message;
  }

  @Action
  public async logout() {
    this.context.commit("setUserData", null);
    this.context.commit("setAuthToken", null);

    Cookie.remove("mkAuth"); // new MK JWT
    Cookie.remove("esyoil_com"); // old php session cookie
  }

  @Mutation
  setDeliveryAddresses(data: any) {
    this.userDeliveryAddresses = data;
  }
  @Mutation
  setAuthToken(token: string | null) {
    this.authToken = token;
  }

  @Mutation
  public setUserData(data: any) {
    this.userData = data;
  }

  public get authHeader() {
    return {
      headers: {
        Authorization: `Bearer ${this.authToken}`,
      },
    };
  }

  public get isLoggedIn() {
    return this.authToken !== null;
  }

  public get user() {
    return this.userData;
  }

  public get customerId() {
    return this.userData ? this.userData.kid : false;
  }

  public get compressedUnloadPoints() {
    if (!this.userDeliveryAddresses) return [];
    return [
      {
        value: null,
        name: "Neue Adressdaten angeben",
      },
      ...this.userDeliveryAddresses.map((a) => {
        return {
          value: a.id,
          name: `${a.honorific} ${a.name.given} ${a.name.family}, ${a.address.street}, ${a.address.zipcode} ${a.address.location}`,
          zipcode: a.address.zipcode,
        };
      }),
    ];
  }
}

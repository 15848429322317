import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { OptoutTypes, RatingOptOut } from "~/types/OptoutTypes";
import { PublicAmiApi } from "@esyoil-gmbh/ami-api-client";

const api = new PublicAmiApi({ basePath: process.env.BACKBONE_ESYOIL });

@Module({
  name: "optout",
  namespaced: true,
  stateFactory: true,
})
export default class Optout extends VuexModule {
  public success = false;
  public loading = true;

  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setSuccess(success: boolean) {
    this.success = success;
  }

  @Action
  async sendOptout(b: RatingOptOut) {
    this.context.commit("setLoading", true);
    this.context.commit("setSuccess", false);
    try {
      const req = await api.publicAmiV1OptoutPost({
        type: OptoutTypes.RATING,
        meta: b,
      });
      this.context.commit("setLoading", false);
      this.context.commit("setSuccess", true);
      return true;
    } catch (err) {
      this.context.commit("setLoading", false);
      this.context.commit("setSuccess", false);
      return false;
    }
  }
}

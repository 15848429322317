// Libraries
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import axios from "axios";
import { CaclulatorRequestParams } from "~/types/HeatingOilCalculator";
import dayjs from "dayjs";

@Module({
  name: "caclulatorstore",
  namespaced: true,
  stateFactory: true,
})
export default class CaclulatorStore extends VuexModule {
  dealerOffersData = {
    errorMessage: "",
    dealerOffers: [],
  };
  availabilities = [];

  averageDeliveryTime = 20;
  minDeliveryTime = 0;

  currentTime = "";

  @Mutation
  setDealerOffersErrorMessage(message) {
    this.dealerOffersData.errorMessage = message;
  }

  @Mutation
  setMinDeliveryTime(data) {
    const min = Math.min(...data.data.map((ele) => ele.delivery.durationDays));
    this.minDeliveryTime = min;
  }

  @Mutation
  setDealerAverageDeliveryTime(data) {
    let sum = 0;
    data.data.forEach((dealer) => {
      sum += dealer.delivery.durationDays;
    });
    this.averageDeliveryTime = Math.floor(sum / data.data.length);
  }

  @Mutation
  setDealerOffers(data) {
    if (!process.server) {
      localStorage.setItem("orderToken", data.meta._token);
    }
    const fastestDelivery = data.data.reduce((fastest, item) => {
      const itemDate = new Date(item.delivery.date);
      const fastestDate = fastest ? new Date(fastest.delivery.date) : null;

      if (!fastestDate || itemDate < fastestDate) {
        return item;
      } else {
        return fastest;
      }
    }, null);

    this.dealerOffersData.dealerOffers = data.data.map((item) => {
      const itemDate = new Date(item.delivery.date);
      const fastestDate = new Date(fastestDelivery.delivery.date);

      item.fastestDelivery = itemDate.getTime() === fastestDate.getTime();
      return item;
    });
    this.currentTime = dayjs().format("HH:mm");
  }

  @Mutation
  setAvailabilities(data: any) {
    this.availabilities = data;
  }

  @Mutation
  sortDealerOffers(sortOptions: {
    sortByParameter: string;
    direction: "asc" | "desc";
  }) {
    function sortPriceAsc(a, b) {
      if (Number(a.pricing._100L.brutto) < Number(b.pricing._100L.brutto))
        return -1;
      if (Number(a.pricing._100L.brutto) > Number(b.pricing._100L.brutto))
        return 1;
      return 0;
    }
    function sortPriceDesc(a, b) {
      if (Number(a.pricing._100L.brutto) < Number(b.pricing._100L.brutto))
        return 1;
      if (Number(a.pricing._100L.brutto) > Number(b.pricing._100L.brutto))
        return -1;
      return 0;
    }
    function sortNameAsc(a, b) {
      if (a.dealer.name.toUpperCase() < b.dealer.name.toUpperCase()) return -1;
      if (a.dealer.name.toUpperCase() > b.dealer.name.toUpperCase()) return 1;
      return 0;
    }
    function sortNameDesc(a, b) {
      if (a.dealer.name.toUpperCase() < b.dealer.name.toUpperCase()) return 1;
      if (a.dealer.name.toUpperCase() > b.dealer.name.toUpperCase()) return -1;
      return 0;
    }
    function sortDeliveryDateAsc(a, b) {
      if (a.delivery.date < b.delivery.date) return -1;
      if (a.delivery.date > b.delivery.date) return 1;
      return 0;
    }
    function sortDeliveryDateDesc(a, b) {
      if (a.delivery.date < b.delivery.date) return 1;
      if (a.delivery.date > b.delivery.date) return -1;
      return 0;
    }
    function sortRatingAsc(a, b) {
      let ab = a.dealer.rating.averageRating * a.dealer.rating.countRating;
      let bb = b.dealer.rating.averageRating * b.dealer.rating.countRating;
      ab = isNaN(ab) ? 0 : ab;
      bb = isNaN(bb) ? 0 : bb;

      if (ab < bb) return -1;
      if (ab > bb) return 1;
      return 0;
    }
    function sortRatingDesc(a, b) {
      let ab = a.dealer.rating.averageRating * a.dealer.rating.countRating;
      let bb = b.dealer.rating.averageRating * b.dealer.rating.countRating;
      ab = isNaN(ab) ? 0 : ab;
      bb = isNaN(bb) ? 0 : bb;

      if (ab < bb) return 1;
      if (ab > bb) return -1;
      return 0;
    }
    if (
      sortOptions.sortByParameter === "price" &&
      sortOptions.direction === "asc"
    )
      this.dealerOffersData.dealerOffers.sort(sortPriceAsc);
    if (
      sortOptions.sortByParameter === "price" &&
      sortOptions.direction === "desc"
    )
      this.dealerOffersData.dealerOffers.sort(sortPriceDesc);
    if (
      sortOptions.sortByParameter === "name" &&
      sortOptions.direction === "asc"
    )
      this.dealerOffersData.dealerOffers.sort(sortNameAsc);
    if (
      sortOptions.sortByParameter === "name" &&
      sortOptions.direction === "desc"
    )
      this.dealerOffersData.dealerOffers.sort(sortNameDesc);
    if (
      sortOptions.sortByParameter === "delivery-date" &&
      sortOptions.direction === "asc"
    )
      this.dealerOffersData.dealerOffers.sort(sortDeliveryDateAsc);
    if (
      sortOptions.sortByParameter === "delivery-date" &&
      sortOptions.direction === "desc"
    )
      this.dealerOffersData.dealerOffers.sort(sortDeliveryDateDesc);
    if (
      sortOptions.sortByParameter === "rating" &&
      sortOptions.direction === "asc"
    )
      this.dealerOffersData.dealerOffers.sort(sortRatingAsc);
    if (
      sortOptions.sortByParameter === "rating" &&
      sortOptions.direction === "desc"
    )
      this.dealerOffersData.dealerOffers.sort(sortRatingDesc);
  }

  @Action
  async fetchDealerOffers(data: CaclulatorRequestParams) {
    this.context.commit("setDealerOffersErrorMessage", "");
    try {
      const result = await axios({
        method: "post",
        url: `${process.env.BACKBONE_ESYOIL}${process.env.CALC_DEALER_OFFERS}`,
        headers: { "Content-Type": "application/json" },
        data: data,
      });

      this.context.commit("setDealerOffers", result.data);
      this.context.commit("setDealerAverageDeliveryTime", result.data);
      this.context.commit("setMinDeliveryTime", result.data);
    } catch (error) {
      if (error.response.data.customStatusCode) {
        this.context.commit(
          "setDealerOffersErrorMessage",
          error.response.data.customStatusCode,
        );
      } else {
        this.context.commit("setDealerOffersErrorMessage", error.response.data);
      }
    }
  }

  @Action
  fetchAndSortDealerOffers(data: {
    dealerParameters: CaclulatorRequestParams;
    sortOptions: { sortByParameter: string; direction: "asc" | "desc" };
  }) {
    this.context.commit("setDealerOffersErrorMessage", "");
    axios({
      method: "post",
      url: `${process.env.BACKBONE_ESYOIL}${process.env.CALC_DEALER_OFFERS}`,
      headers: { "Content-Type": "application/json" },
      data: data.dealerParameters,
    })
      .then((response) => {
        this.context.commit("setDealerOffers", response.data);
        this.context.commit("sortDealerOffers", data.sortOptions);
      })
      .catch((error) => {
        console.error(error.response.data.customStatusCode);
        if (error.response.data.customStatusCode) {
          this.context.commit(
            "setDealerOffersErrorMessage",
            error.response.data.customStatusCode,
          );
        } else {
          this.context.commit(
            "setDealerOffersErrorMessage",
            error.response.data,
          );
        }
      });
  }

  @Action
  async fetchAvailableOptions(requestData: any) {
    if (requestData.zipcode && requestData.amount) {
      try {
        const result = await axios({
          method: "post",
          // eslint-disable-next-line no-undef
          url: `${process.env.BACKBONE_ESYOIL}${process.env.AVAILABILITY_CHECK}`,
          headers: { "Content-Type": "application/json" },
          data: {
            zipcode: requestData.zipcode,
            amount: requestData.amount,
          },
        });
        this.context.commit("setAvailabilities", result.data);
      } catch (error) {
        console.error(error);
      }
    }
  }
}

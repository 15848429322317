// Libraries
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import axios from "axios";

@Module({
  name: "tankreinigung",
  namespaced: true,
  stateFactory: true,
})
export default class Tankreinigung extends VuexModule {
  optionSets = {
    producerOptionSet: [],
    tankTypeOptionSet: [],
    tankQuantityOptionSet: [],
  };
  bearingDataTable = [];
  speacialistComapnies = [];
  error = "";

  @Mutation
  setProducersOptions(data: Array<string>) {
    this.optionSets.producerOptionSet = [];
    data.forEach((producer) => {
      const OptionSet = {
        name: producer,
        value: producer,
      };
      this.optionSets.producerOptionSet.push(OptionSet);
    });
  }

  @Mutation
  setTankTypesOptionSet(data: Array<{ name: string; _id: string }>) {
    this.optionSets.tankTypeOptionSet = [];
    data.forEach((tankType) => {
      const OptionSet = {
        name: tankType.name,
        value: tankType.name,
      };
      this.optionSets.tankTypeOptionSet.push(OptionSet);
    });
    const OptionSet = {
      name: "bitte Wählen",
      value: "",
    };
    this.optionSets.tankTypeOptionSet.unshift(OptionSet);
  }

  @Mutation
  setTankQuantityOptions(data: Array<number>) {
    this.optionSets.tankQuantityOptionSet = [];
    data.forEach((quantityValue) => {
      const OptionSet = {
        name: quantityValue,
        value: quantityValue,
      };
      this.optionSets.tankQuantityOptionSet.push(OptionSet);
    });
    const OptionSet = {
      name: "bitte Wählen",
      value: "",
    };
    this.optionSets.tankQuantityOptionSet.unshift(OptionSet);
  }

  @Mutation
  setBearingDataTable(tableData: Array<{ cm: string; liter: string }>) {
    this.bearingDataTable = [];
    this.bearingDataTable = tableData;
  }

  @Mutation
  setSpeacialistCompanies(
    speacialistComapniesData: Array<{
      city: string;
      company: string;
      phone: string;
      street: string;
      zipcode: string;
    }>,
  ) {
    this.speacialistComapnies = [];
    this.speacialistComapnies = speacialistComapniesData;
  }

  @Action
  async fetchProducerList() {
    try {
      const result = await axios.get(
        `${process.env.BACKBONE_ESYOIL}/tank-protector/v1/manufacturer`,
      );
      this.context.commit("setProducersOptions", result.data);
      this.context.dispatch("fetchTankTypes", result.data[0]);
    } catch (error) {
      this.context.commit("setErrorMessage", error);
    }
  }

  @Action
  async fetchTankTypes(producerName: string) {
    try {
      const result = await axios.get(
        `${process.env.BACKBONE_ESYOIL}/tank-protector/v1/tank-types/${producerName}`,
      );
      this.context.commit("setTankTypesOptionSet", result.data);
      this.context.dispatch("fetchTankQuantity", result.data[0].name);
    } catch (error) {
      this.context.commit("setErrorMessage", error);
    }
  }

  @Action
  async fetchTankQuantity(tankTypeID) {
    try {
      const result = await axios.get(
        `${process.env.BACKBONE_ESYOIL}/tank-protector/v1/tank-types/${tankTypeID}/count`,
      );
      this.context.commit(
        "setTankQuantityOptions",
        Array(result.data)
          .fill(1)
          .map((v, k) => v + k),
      );
    } catch (error) {
      this.context.commit("setErrorMessage", error);
    }
  }

  @Action
  async fetchBearingTableData(data: {
    tankTypeID: string;
    tankQuantity: string;
  }) {
    try {
      const result = await axios.get(
        `${process.env.BACKBONE_ESYOIL}/tank-protector/v1/bearingTable/${data.tankTypeID}/${data.tankQuantity}`,
      );
      this.context.commit("setBearingDataTable", result.data);
    } catch (error) {
      this.context.commit("setErrorMessage", error);
    }
  }

  @Action
  async fetchSpecialistCompanies(zipcode) {
    try {
      const result = await axios.get(
        `${process.env.BACKBONE_ESYOIL}/tank-protector/v1/search/${zipcode}`,
      );
      this.context.commit("setSpeacialistCompanies", result.data);
    } catch (error) {
      this.context.commit("setErrorMessage", error);
    }
  }
}

// Libraries
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import axios from "axios";
import dayjs from "dayjs";

@Module({
  name: "schwarmometer",
  namespaced: true,
  stateFactory: true,
})
export default class Schwarmometer extends VuexModule {
  priceDevelopmentDirection = "";
  userSignal = {
    buyingIntesionString: "unbekannt",
    buyingIntesionIndex: 0,
  };
  esyoilSignal = {
    buyingIntesionString: "unbekannt",
    buyingIntesionDirection: "",
    buyingIntesionIndex: 0,
  };
  error = "";

  @Mutation
  setErrorMessage(errorMessage: string) {
    this.error = errorMessage;
  }

  @Mutation
  setPriceDevelopmentDirection(priceDevelopmentDirection: string) {
    this.priceDevelopmentDirection = priceDevelopmentDirection;
  }

  @Mutation
  setUserSignal(userSignalValues: {
    good_moment_customers_str: string;
    good_moment_customers_int: number;
  }) {
    this.userSignal.buyingIntesionString =
      userSignalValues.good_moment_customers_str;
    this.userSignal.buyingIntesionIndex =
      userSignalValues.good_moment_customers_int;
  }

  @Mutation
  setEsyoilSignal(esyoilSignalValues: {
    hs_str: string;
    hs_cls: string;
    hs_int: number;
  }) {
    this.esyoilSignal.buyingIntesionString = esyoilSignalValues.hs_str;
    this.esyoilSignal.buyingIntesionDirection = esyoilSignalValues.hs_cls;
    this.esyoilSignal.buyingIntesionIndex = esyoilSignalValues.hs_int;
  }

  @Action
  async fetchPriceDevelopmentDirection() {
    this.context.commit("setPriceDevelopmentDirection", "loading");
    try {
      const result = await axios.get(
        `${process.env.API_ESYOIL}${process.env.HEIZOEL_SIGNAL}`,
      );
      this.context.commit(
        "setPriceDevelopmentDirection",
        result.data.data.hs_cls,
      );
    } catch (error) {
      this.context.commit("setErrorMessage", error);
    }
  }

  @Action
  async fetchUserSignal() {
    this.context.commit("setPriceDevelopmentDirection", "loading");

    try {
      const result = await axios.get(
        `${process.env.API_ESYOIL}${process.env.USERS_SIGNAL}`,
      );
      this.context.commit("setUserSignal", result.data.data);
    } catch (error) {
      this.context.commit("setErrorMessage", error);
    }
  }

  @Action
  async fetchEsyoilSignal(zipcode: string) {
    this.context.commit("setPriceDevelopmentDirection", "loading");
    let url = "";

    if (zipcode) {
      url = `${process.env.API_ESYOIL}${process.env.HEIZOEL_SIGNAL}/${zipcode}`;
    } else {
      url = `${process.env.API_ESYOIL}${process.env.HEIZOEL_SIGNAL}`;
    }

    try {
      const result = await axios.get(url);
      this.context.commit("setEsyoilSignal", result.data.data);
    } catch (error) {
      this.context.commit("setErrorMessage", error);
    }
  }

  swarmData = {
    labelData: [],
    priceData: [],
    recommendationPhaseData: [],
  };
  @Mutation
  setSwarmData(series: Array<{ date: number; price: number; bar: number }>) {
    for (let i = 0; i < series.length; i++) {
      this.swarmData.priceData.push({
        x: series[i].date,
        y: series[i].price,
      });
      this.swarmData.recommendationPhaseData.push({
        x: series[i].date,
        y: series[i].bar,
      });
      this.swarmData.labelData.push(
        dayjs(series[i].date * 1000).format("DD.MM.YYYY"),
      );
    }
  }
  @Action
  async fetchSwarmData() {
    // this.context.commit('setPriceDevelopmentDirection', 'loading');
    try {
      const result = await axios.get(
        `${process.env.API_ESYOIL}${process.env.SWARM}`,
      );

      this.context.commit("setSwarmData", result.data.data.series);
    } catch (error) {
      this.context.commit("setErrorMessage", error);
    }
  }

  esyoilSignalData = {
    labelData: [],
    priceData: [],
    recommendationPhaseData: [],
  };
  @Mutation
  setPriceSystemData(
    series: Array<{ date: number; price: number; bar: number }>,
  ) {
    for (let i = 0; i < series.length; i++) {
      this.esyoilSignalData.priceData.push({
        x: series[i].date,
        y: series[i].price,
      });
      this.esyoilSignalData.recommendationPhaseData.push({
        x: series[i].date,
        y: series[i].bar,
      });
      this.esyoilSignalData.labelData.push(
        dayjs(series[i].date).format("DD.MM.YYYY"),
      );
    }
  }
  @Action
  async fetchPriceSystemData(zipcode: string) {
    // this.context.commit('setPriceDevelopmentDirection', 'loading');
    try {
      const result = await axios.get(
        `${process.env.API_ESYOIL}${process.env.LOW_PRICE_SYSTEM}/${zipcode}`,
      );
      this.context.commit("setPriceSystemData", result.data.data.series);
    } catch (error) {
      this.context.commit("setErrorMessage", error);
    }
  }
}

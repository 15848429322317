// Libraries
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import axios from "axios";
import dayjs from "dayjs";
import {
  PartnerOrderPriceResponseData,
  PreselectionDataKeys,
  RatepayData,
  UnloadingPointFormArray,
  UnloadingPointObject,
  UserOfferPriceRequest,
  UserOfferRequest,
} from "~/types/Checkout";
import heatingOilOrderDataDefault from "~/defaults/checkout/heatingOilOrderData";
import headerSetupDefault from "~/defaults/checkout/headerSetup";
import { findObjectByKeySetError } from "~/operations/missing-search-functions";
import { EsyOilContact } from "~/operations/messages";
import { sha1 } from "js-sha1";

@Module({
  name: "checkout",
  namespaced: true,
  stateFactory: true,
})
export default class Checkout extends VuexModule {
  asGuest = false;
  authentication = {
    token: "",
  };
  headerSetup = JSON.parse(JSON.stringify(headerSetupDefault));
  heatingOilOrderData = JSON.parse(JSON.stringify(heatingOilOrderDataDefault));

  autofillZipcodes = {
    selection: "",
    selectedOption: 0,
    keySelection: "",
    options: [],
  };

  autofillCities = {
    selection: "",
    selectedOption: 0,
    keySelection: "",
    options: [],
  };

  supportZipcodesMessage = "";
  supportCityMessage = "";
  zipError = false;
  cityValue = "";
  disable = false;

  public get authenticationToken(): string {
    return this.authentication.token;
  }

  public get heatingOilOrder(): Record<string, unknown> {
    return this.heatingOilOrderData.heatingOilOrder;
  }

  public get heatingOilOrderZipcode(): string {
    return this.heatingOilOrderData.heatingOilOrder.customer.zipcode;
  }

  public get orderPaymentType(): string {
    return this.heatingOilOrderData.heatingOilOrder.order.payment_type;
  }

  public get orderRatePayAmount(): string {
    return this.heatingOilOrderData.heatingOilOrder.order.ratepayData.amount;
  }

  public get _heatingOilOrderData() {
    return this.heatingOilOrderData;
  }

  public get esyoilRatingForm() {
    return this.heatingOilOrderData.orderStatus.success.dealId % 5 === 0;
  }

  get paylaToken() {
    return `YDsFIvGXSP7M_esyoil_${sha1(this.authenticationToken)}`;
  }

  @Mutation
  setHeatingOilOrderData(data) {
    // trigger deep reactivity in Vue
    this.heatingOilOrderData = JSON.parse(JSON.stringify(data));
  }

  @Mutation
  setAsGuest(a: boolean) {
    this.asGuest = a;
  }

  @Mutation
  setUnloadingPointFormErrorMessage(errorData: {
    index: number;
    emitTarget: string;
    errorMessage: string;
  }) {
    function findObjectByKeySetError(
      array,
      formIndex,
      key,
      value,
      errorMessage,
    ) {
      for (
        let inputIndex = 0;
        inputIndex < array[formIndex].length;
        inputIndex++
      ) {
        if (array[formIndex][inputIndex][key] === value) {
          array[formIndex][inputIndex].supportMessage = errorMessage;
        }
      }
      return null;
    }
    findObjectByKeySetError(
      this.heatingOilOrderData.forms.unloadingPointsForms,
      errorData.index,
      "emitTarget",
      errorData.emitTarget,
      errorData.errorMessage,
    );
  }

  @Mutation
  setAddressFormErrorMessage(errorData: {
    emitTarget: string;
    errorMessage: string;
  }) {
    findObjectByKeySetError(
      this.heatingOilOrderData.forms.deliveryAddressFormData,
      "emitTarget",
      errorData.emitTarget,
      errorData.errorMessage,
    );
  }

  @Mutation
  setFormError(errorData: {
    relatedFormObjectKey: string;
    emitTarget: string;
    errorMessage: string;
  }) {
    findObjectByKeySetError(
      this.heatingOilOrderData.forms[errorData.relatedFormObjectKey],
      "emitTarget",
      errorData.emitTarget,
      errorData.errorMessage,
    );
  }

  @Mutation
  setContactFormErrorMessage(errorData: {
    emitTarget: string;
    errorMessage: string;
  }) {
    findObjectByKeySetError(
      this.heatingOilOrderData.forms.contactFormData,
      "emitTarget",
      errorData.emitTarget,
      errorData.errorMessage,
    );
  }

  @Mutation
  clearAutofillStreetNames() {
    this.heatingOilOrderData.autoCompletion.streetNames = [];
  }

  @Mutation
  formatInvalidDate() {
    const outputDate =
      this.heatingOilOrderData.heatingOilOrder.customer.birthday;
    if (outputDate.includes(".")) {
      const DateSegments = outputDate.split(".");
      this.heatingOilOrderData.heatingOilOrder.customer.birthday = `${DateSegments[2]}-${DateSegments[1]}-${DateSegments[0]}`;
    }
  }

  @Mutation
  presetCityFormInputValues(city: { zipcode: string; name: string }) {
    if (
      city.zipcode !== undefined &&
      city.zipcode !== null &&
      city.zipcode !== "" &&
      city.zipcode.length === 5
    )
      this.heatingOilOrderData.heatingOilOrder.customer.zipcode = city.zipcode;
    this.heatingOilOrderData.heatingOilOrder.customer.city = city.name;
  }

  @Mutation
  setPartnerIDs(partnerIDs: {
    businessCustomerId: string;
    deliveryAreaId: string;
    legacyId: number;
  }) {
    this.heatingOilOrderData.heatingOilOrder.dealer.businessCustomerId =
      partnerIDs.businessCustomerId;
    this.heatingOilOrderData.heatingOilOrder.dealer.deliveryAreaId =
      partnerIDs.deliveryAreaId;
    this.heatingOilOrderData.heatingOilOrder.dealer.legacyId =
      partnerIDs.legacyId;
  }

  @Mutation
  setPartnerRating(rating: number) {
    this.heatingOilOrderData.heatingOilOrder.dealer.rating = rating;
  }

  @Mutation
  setCustomerDealterNotice(message: string) {
    this.heatingOilOrderData.heatingOilOrder.customer.noticeCustomerToDealer =
      message;
  }

  @Mutation
  setGlobalErrorMessage(message: string) {
    this.heatingOilOrderData.orderStatus.globalErrorMessage = message;
  }

  @Mutation
  setBuyBindingStep(status = true) {
    this.heatingOilOrderData.orderStatus.buyBindingStep = status;
  }

  @Mutation
  saveDeliveryDateToStore(deliveryDate: string) {
    const FormatedDate = dayjs(deliveryDate).format("DD.MM.YYYY");
    this.heatingOilOrderData.orderStatus.deliveryDate = FormatedDate;
    this.heatingOilOrderData.orderStatus.deliveryDateRaw = deliveryDate;
  }

  @Mutation
  setCheckoutAuthToken(authtoken: string) {
    const DecodedToken = atob(authtoken);
    this.authentication.token = DecodedToken;
  }

  @Mutation
  setRatePayTransactionData(ratePayVerificationData: {
    transactionId: string;
    descriptor: string;
  }) {
    this.heatingOilOrderData.heatingOilOrder.order.ratepayData.transactionId =
      ratePayVerificationData.transactionId;
    this.heatingOilOrderData.heatingOilOrder.order.ratepayData.descriptor =
      ratePayVerificationData.descriptor;
  }

  @Mutation
  setOrderStatusShowCheckboxes(status = true) {
    this.heatingOilOrderData.orderStatus.buyBindingStep = status;
    this.heatingOilOrderData.orderStatus.showCheckboxes = status;
  }

  @Mutation
  setOrderStatusSuccess(status: {
    dealId: string;
    trustedShopLink: string;
    success: boolean | string;
    error: string;
    message: string;
    errorOrigin: string;
  }) {
    this.heatingOilOrderData.orderStatus.success.error = "";
    this.heatingOilOrderData.orderStatus.success.errorMessage = "";
    this.heatingOilOrderData.orderStatus.success.status = status.success;
    this.heatingOilOrderData.orderStatus.success.dealId =
      "Fehlerhafter Kaufprozess";

    switch (status.success) {
      case "reset":
        this.heatingOilOrderData.orderStatus.success.dealId = null;
        this.heatingOilOrderData.orderStatus.success.trustedShopLink = "";
        this.heatingOilOrderData.orderStatus.success.status = null;
        break;
      default:
        this.heatingOilOrderData.orderStatus.success.dealId = status.dealId;
        this.heatingOilOrderData.orderStatus.success.trustedShopLink =
          status.trustedShopLink;
        this.heatingOilOrderData.orderStatus.success.error = status.error;
        this.heatingOilOrderData.orderStatus.success.errorMessage =
          status.errorOrigin == "guaranteedPayment"
            ? `
            Leider ist eine Bezahlung mit Ratepay-Ratenkauf nicht möglich. Diese Entscheidung ist auf Grundlage einer automatisierten Datenverarbeitung getroffen worden und kann unterschiedliche Gründe haben.
            <br>
            <br>
            Bitte überprüfen Sie die korrekte Eingabe Ihrer persönlichen Daten. Sollte das Problem weiterhin bestehen, wenden Sie sich bei Fragen bitte direkt an Ratepay oder wählen eine alternative Zahlart.
          `
            : `Es tut uns Leid, bei Ihrer Kaufanfrage ist ein Fehler aufgetreten. Versuchen Sie es zu einem späteren Zeitpunkt erneut, rufen Sie uns unter ${EsyOilContact.customerServicePhone} an oder bestellen Sie über unsere App.`;
        break;
    }
  }

  @Mutation
  setOrderStatusCompleted(status = true) {
    this.heatingOilOrderData.orderStatus.completed = status;
  }

  @Mutation
  setOrderStatusError(payload: { objectKey: string; message: string }) {
    this.heatingOilOrderData.orderStatus.error[payload.objectKey] =
      payload.message;
  }

  @Mutation
  setOrderStatusAGB(status: boolean) {
    this.heatingOilOrderData.orderStatus.checkedAGB = status;
  }

  @Mutation
  setOrderStatusDSGVO(status: boolean) {
    this.heatingOilOrderData.orderStatus.checkedDSGVO = status;
  }

  @Mutation
  setOrderStatusSEPA(status: boolean) {
    this.heatingOilOrderData.orderStatus.checkedSEPA = status;
  }

  @Mutation
  resetCheckoutCheckboxes() {
    this.heatingOilOrderData.orderStatus.checkedAGB = false;
    this.heatingOilOrderData.orderStatus.checkedDSGVO = false;
    this.heatingOilOrderData.orderStatus.checkedSEPA = false;
  }

  @Mutation
  resetSuccessfulyCheckout() {
    this.heatingOilOrderData.orderStatus.success.trustedShopLink = "";
    this.heatingOilOrderData.orderStatus.success.status = null;
  }

  @Mutation
  setHeatingOilOrder(basicOrderData: UserOfferRequest) {
    this.heatingOilOrderData.heatingOilOrder.order.zipcode =
      basicOrderData.zipcode;
    this.heatingOilOrderData.heatingOilOrder.order.prod = basicOrderData.prod;
    this.heatingOilOrderData.heatingOilOrder.order.amount = Number(
      basicOrderData.amount,
    );
    this.heatingOilOrderData.heatingOilOrder.order.payment_type =
      basicOrderData.payment_type;
    this.heatingOilOrderData.heatingOilOrder.order.unloading_points = Number(
      basicOrderData.unloading_points,
    );
    this.heatingOilOrderData.heatingOilOrder.order.hose = basicOrderData.hose;
    this.heatingOilOrderData.heatingOilOrder.order.short_vehicle =
      basicOrderData.short_vehicle;
    this.heatingOilOrderData.heatingOilOrder.order.deliveryTimes =
      basicOrderData.deliveryTimes;
  }

  @Mutation
  pushUnloadingPointForm(unloadingPointForm: UnloadingPointFormArray) {
    this.heatingOilOrderData.forms.unloadingPointsForms.push(
      unloadingPointForm,
    );
  }

  @Mutation
  setValidComparsionPrice(validComparsionPrice) {
    this.heatingOilOrderData.orderStatus.validComparsionPrice =
      validComparsionPrice;
  }

  @Mutation
  setBillingAddressForm(status: boolean) {
    const FormModel = {
      company: "",
      salutation: "",
      prename: "",
      lastname: "",
      street: "",
      streetNumber: "",
      zipcode: "",
      city: "",
      phone: "",
    };
    if (status === true)
      this.heatingOilOrderData.heatingOilOrder.customer.billingAddress =
        FormModel;
    if (status === false)
      this.heatingOilOrderData.heatingOilOrder.customer.billingAddress = null;
  }

  @Mutation
  setUnloadingPointData(payload: {
    index: number;
    objectKey: string;
    value: string | number;
  }) {
    this.heatingOilOrderData.heatingOilOrder.customer.otherUnloadingPoints[
      payload.index
    ][payload.objectKey] = payload.value;
  }

  @Mutation
  setCheckoutHeaderBackwardsButtonVisibility(visibility: boolean) {
    this.headerSetup.showNavigateBackwardsButton = visibility;
  }

  @Mutation
  setCheckoutHeaderGotoShopLinkVisibility(visibility: boolean) {
    this.headerSetup.showGotoShopLink = visibility;
  }

  @Mutation
  setOwnDistributedAmount(amount: string) {
    this.heatingOilOrderData.selection.ownDistributedAmount = amount;
  }

  @Mutation
  setOwnDistributedAmountError(errormessage: string) {
    this.heatingOilOrderData.selection.ownDistributedAmountError = errormessage;
  }

  @Mutation
  setOrder100LiterPrice(payload: {
    product: string;
    responseData: PartnerOrderPriceResponseData;
  }) {
    this.heatingOilOrderData.heatingOilOrder.order.netto100lprice =
      payload.responseData.product[
        payload.product
      ].priceListEntry[0].pricing._100L.netto;
  }

  @Mutation
  clearUnloadingPoint() {
    this.heatingOilOrderData.heatingOilOrder.customer.otherUnloadingPoints = [];
  }

  @Mutation
  pushUnloadingPoint(unloadingPointData: UnloadingPointObject) {
    this.heatingOilOrderData.heatingOilOrder.customer.otherUnloadingPoints.push(
      unloadingPointData,
    );
  }

  @Mutation
  clearAutoCompletionStreetNames() {
    this.heatingOilOrderData.autoCompletion.streetNames = [];
  }

  @Mutation
  setPlacesOptions(payload: {
    places: { GEMEINDE_NAME: string }[];
    isBilling: boolean;
  }) {
    const { places, isBilling } = payload;
    const options = places.map((place) => {
      return {
        name: place.GEMEINDE_NAME,
        value: place.GEMEINDE_NAME,
      };
    });

    // For use when checking if a city exists as a saved address is set.
    this.heatingOilOrderData.autoCompletion.placeNames = options;

    // Autocomplete options used in the dropdown are stored below
    let field;
    if (isBilling)
      field = this.heatingOilOrderData.forms.billingAddressFormData.find(
        (field) => field.emitTarget === "city",
      );
    else
      field = this.heatingOilOrderData.forms.deliveryAddressFormData.find(
        (field) => field.emitTarget === "city",
      );

    if (places.length > 1) {
      field.disabled = false;
      options.unshift({ name: "Bitte wählen", value: "" });
    } else field.disabled = true;

    field.options = options;
  }

  /*@Mutation
  pushAutoCompletionStreetNames(streetNames: Array<{ _id: string }>) {
    this.heatingOilOrderData.autoCompletion.streetNames = [];
    streetNames.forEach((streetName) => {
      this.heatingOilOrderData.autoCompletion.streetNames.push(streetName._id);
    });
  }*/

  @Mutation
  setCustomerData(payload: {
    objectKey: string;
    value: string | number;
    exeption: string;
  }) {
    if (!payload.exeption)
      this.heatingOilOrderData.heatingOilOrder.customer[payload.objectKey] =
        payload.value;
  }

  @Mutation
  setCustomerBillingData(payload: { objectKey: string; value: string }) {
    this.heatingOilOrderData.heatingOilOrder.customer.billingAddress[
      payload.objectKey
    ] = payload.value;
  }

  @Mutation
  setDistributableAmount(amount: number) {
    this.heatingOilOrderData.selection.distributableAmount = amount;
  }

  @Mutation
  setOrderPrice(orderData: {
    totalOrderPrice: number;
    totalOrderAmount: number;
  }) {
    const Netto100LPrice =
      (orderData.totalOrderPrice / orderData.totalOrderAmount) * 100;
    this.heatingOilOrderData.heatingOilOrder.order.netto100lprice =
      Netto100LPrice;
  }

  @Mutation
  resetHeatingOilOrderData() {
    this.heatingOilOrderData.product = [];
    this.heatingOilOrderData.deliveryTime = [];
  }

  @Mutation
  setratepayData(ratepayData) {
    this.heatingOilOrderData.heatingOilOrder.order.ratepayData = ratepayData;
  }

  @Mutation
  pushRatePayOptions(ratePayOptions: Array<RatepayData>) {
    this.heatingOilOrderData.ratePlanOptions.results = ratePayOptions;
  }

  @Mutation
  setInitialHeationgOilParameters(payload: {
    responseDeliveryData: PartnerOrderPriceResponseData;
    preselectionKeys: PreselectionDataKeys;
  }) {
    const ProductReference = payload.responseDeliveryData.product;
    const DeliveryReference = payload.responseDeliveryData.deliveryTime;
    const SelectedProduct = payload.preselectionKeys.product;
    const SelectedDelivery = payload.preselectionKeys.deliveryTime;

    for (const objectKey in ProductReference) {
      if (
        ProductReference[objectKey].label !== "no name" &&
        ProductReference[objectKey].label !== "" &&
        ProductReference[objectKey].label !== null
      ) {
        ProductReference[objectKey].id = objectKey;
        const extractedDeliveryPrice =
          ProductReference[objectKey].price -
          ProductReference[SelectedProduct].price;
        ProductReference[objectKey].additionalCosts =
          extractedDeliveryPrice.toFixed(2);
        this.heatingOilOrderData.product.push(ProductReference[objectKey]);
      }
    }

    // if (!Object.keys(DisabledDeliveryTimesForAddedValues).includes(payload.preselectionKeys.deliveryTime)) {
    for (const objectKey in DeliveryReference) {
      if (
        DeliveryReference[objectKey].label !== "no name" &&
        DeliveryReference[objectKey].label !== "" &&
        DeliveryReference[objectKey].label !== null
      ) {
        DeliveryReference[objectKey].id = objectKey;
        const extractedDeliveryPrice =
          DeliveryReference[objectKey].price -
          DeliveryReference[SelectedDelivery].price;
        DeliveryReference[objectKey].additionalCosts =
          extractedDeliveryPrice.toFixed(2);
        this.heatingOilOrderData.deliveryTime.push(
          DeliveryReference[objectKey],
        );
      }
    }
    // }

    this.heatingOilOrderData.selection.label =
      ProductReference[SelectedProduct].label;
    this.heatingOilOrderData.selection.vat.price =
      ProductReference[
        SelectedProduct
      ].priceListEntry[0].pricing.total.vatMonetary;
    this.heatingOilOrderData.selection.vat.price100L =
      ProductReference[
        SelectedProduct
      ].priceListEntry[0].pricing._100L.vatMonetary;
    this.heatingOilOrderData.selection.deliveryPrice.price =
      ProductReference[
        SelectedProduct
      ].priceListEntry[0].pricing.total.deliveryCharge;
    this.heatingOilOrderData.selection.deliveryPrice.price100L =
      ProductReference[
        SelectedProduct
      ].priceListEntry[0].pricing._100L.deliveryCharge;
    this.heatingOilOrderData.selection.nettoPrice.price =
      ProductReference[SelectedProduct].priceListEntry[0].pricing.total.netto;
    this.heatingOilOrderData.selection.nettoPrice.price100L =
      ProductReference[SelectedProduct].priceListEntry[0].pricing._100L.netto;
    this.heatingOilOrderData.selection.bruttoPrice.price =
      ProductReference[SelectedProduct].priceListEntry[0].pricing.total.brutto;
    this.heatingOilOrderData.selection.bruttoPrice.price100L =
      ProductReference[SelectedProduct].priceListEntry[0].pricing._100L.brutto;
    this.heatingOilOrderData.selection.common.isDeliveryChargeActive =
      ProductReference[
        SelectedProduct
      ].priceListEntry[0].pricing.common.isDeliveryChargeActive;
    this.heatingOilOrderData.selection.common.isDeliveryChargeIncluded =
      ProductReference[
        SelectedProduct
      ].priceListEntry[0].pricing.common.isDeliveryChargeIncluded;
    this.heatingOilOrderData.selection.common.deliveryChargePrice =
      ProductReference[
        SelectedProduct
      ].priceListEntry[0].pricing.common.deliveryChargePrice;
  }

  /**
   * Wenn in den Checkout gegangen wird und dann abgebrochen wird in dem z.B.
   * auf das Logo geklickt wurde und dann wieder in den Checkout gegangen wird.
   * Damm müssen einige Werte zurückgesetzt werden. Da sich der Checkout sonst an
   * einigen Stellen komisch verhält.
   */
  @Action
  resetCheckoutStatus() {
    this.context.commit("setBuyBindingStep", false);
    this.context.commit("setOrderStatusCompleted", false);
    this.context.commit("setOrderStatusShowCheckboxes", false);
    this.context.commit("resetCheckoutCheckboxes", "false");
    this.context.commit("setGlobalErrorMessage", "");
    this.context.commit("resetSuccessfulyCheckout", "");
  }

  @Action
  setupCustomerData(payload: {
    objectKey: string;
    value: string | number;
    //zipcode: number | string;
  }) {
    this.context.commit("setCustomerData", {
      objectKey: payload.objectKey,
      value: payload.value,
    });

    if (
      ["prename", "lastname"].includes(payload.objectKey) &&
      this.heatingOilOrderData.heatingOilOrder.order.payment_type ==
      "guaranteedPayment"
    ) {
      this.context.commit("setCustomerData", {
        objectKey: "ibanOwner",
        value: `${this.heatingOilOrderData.heatingOilOrder.customer.prename} ${this.heatingOilOrderData.heatingOilOrder.customer.lastname}`,
      });
    }

    // The autocomplete suggestions used to be handled and saved globally in the store
    // The purpose of the AutoStreetInput instead of AutofillInput component is to have a local copy of the autocomplete suggestions
    // which is needed when there are multiple street suggestions in one page (E.g Offloading points)
    // The commented functions have been included as an example of how to use the AutofillInput with suggestions saved in the store
    /*if (payload.objectKey === "street" && payload.value !== "") {
      this.context.dispatch("preFetchStreetNames", {
        zipcode: this.context.getters.heatingOilOrderZipcode,
        streetName: payload.value,
      });
    }*/
    if (payload.value === "") {
      this.context.commit("clearAutoCompletionStreetNames");
    }

    this.setHeatingOilOrderData(this._heatingOilOrderData);
  }

  @Action
  updateCustomerBillingData(payload: {
    objectKey: string;
    value: string | number;
  }) {
    if (payload.objectKey === "zipcode") {
      this.context.commit("setCustomerBillingData", {
        objectKey: "city",
        value: "",
      });

      this.context.dispatch("preFetchAvailableCities", {
        zipcode: payload.value,
        isBilling: true,
      });
    }

    this.context.commit("setCustomerBillingData", {
      objectKey: payload.objectKey,
      value: payload.value,
    });

    this.setHeatingOilOrderData(this._heatingOilOrderData);
  }

  @Action
  async fetchHeatingOilCheckoutData(offerData: UserOfferRequest) {
    try {
      const result = await axios({
        method: "post",
        url: `${process.env.BACKBONE_ESYOIL}${process.env.CALC_ADDED_PRICE_VALUE}`,
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
          "X-Validation-Token": this.context.getters.authenticationToken,
        },
        data: offerData,
      });

      this.context.commit("setOrder100LiterPrice", {
        product: offerData.prod,
        responseData: result.data,
      });
      this.context.commit("resetHeatingOilOrderData");
      this.context.commit("setAsGuest", false);
      this.context.commit("setInitialHeationgOilParameters", {
        responseDeliveryData: result.data,
        preselectionKeys: {
          deliveryTime: offerData.deliveryTimes,
          product: offerData.prod,
          amount: offerData.amount,
        },
      });

      if (offerData.payment_type == "guaranteedPayment") {
        this.context.dispatch("fetchRatePayOptions", {
          price: result.data.product[offerData.prod].price,
          preselectionIndex: [3, 6, 9, 12, 18, 24].indexOf(
            offerData.numberOfRates,
          ),
        });
      }

      this.context.commit(
        "saveDeliveryDateToStore",
        result.data.deliveryTime[offerData.deliveryTimes].priceListEntry[0]
          .delivery.date,
      );
    } catch (error) {
      this.context.commit("setGlobalErrorMessage", error.response.data.message);
    }
  }

  @Action
  fetchPriceCheckUpToDate(offerData: UserOfferPriceRequest) {
    axios({
      method: "post",
      url: `${process.env.BACKBONE_ESYOIL}${process.env.PRICE_CHECK}`,
      headers: {
        "Content-Type": "application/json",
        "X-Validation-Token": this.context.getters.authenticationToken,
      },
      data: offerData,
    })
      .then((result) => {
        this.context.commit(
          "setValidComparsionPrice",
          result.data.isPriceActual,
        );
      })
      .catch((error) => {
        this.context.commit(
          "setGlobalErrorMessage",
          error.response.data.message,
        );
      });
  }

  @Action
  async preFetchAvailableCities(payload: {
    zipcode: string;
    isBilling: boolean;
  }) {
    const { zipcode, isBilling } = payload;
    try {
      const result = await axios({
        method: "get",
        url: `${process.env.BACKBONE_ESYOIL}${process.env.ESYOIL_PLACES}/${zipcode}`,
        headers: { Authorization: `Basic ${process.env.PLACES_AUTH}` },
      });

      if (result.data.length === 1)
        this.context.dispatch(
          isBilling ? "updateCustomerBillingData" : "setupCustomerData",
          {
            objectKey: "city",
            value: result.data[0].GEMEINDE_NAME,
          },
        );

      this.context.commit("setPlacesOptions", {
        places: result.data,
        isBilling,
      });
    } catch (error) {
      this.context.commit("setPlacesOptions", {
        places: [],
        isBilling,
      });
      console.error(error.response.data);
    }
  }

  @Mutation
  getZipCodes(result: Array<string>) {
    this.autofillZipcodes.options = [];
    if (result.length !== 0)
      this.autofillZipcodes.options = result
        .map((option) => {
          return option;
        })
        .sort((a, b) => a.localeCompare(b, "de"));
    if (this.autofillZipcodes.options.length === 0) {
      this.supportZipcodesMessage = "falsche Postleitzahl";
      this.zipError = true;
    } else {
      this.supportZipcodesMessage = "";
      this.zipError = false;
    }
  }

  @Mutation
  resetAutofillZipcodesOptions() {
    this.autofillZipcodes.options = [];
  }

  @Mutation
  resetAutofillCitiesOptions() {
    this.autofillCities.options = [];
  }

  @Mutation
  setCityValue(value) {
    this.cityValue = value;
  }

  @Mutation
  navigateZipcodesUp() {
    const selectionItemsQuantity = this.autofillZipcodes.options.length - 1;
    if (this.autofillZipcodes.selectedOption > -1)
      this.autofillZipcodes.selectedOption -= 1;
    if (this.autofillZipcodes.selectedOption === -1)
      this.autofillZipcodes.selectedOption = selectionItemsQuantity;
    const i = this.autofillZipcodes.selectedOption;
    this.autofillZipcodes.keySelection = this.autofillZipcodes.options[i];
  }

  @Mutation
  navigateZipcodesDown() {
    const selectionItemsQuantity = this.autofillZipcodes.options.length;
    const i = this.autofillZipcodes.selectedOption;
    this.autofillZipcodes.keySelection = this.autofillZipcodes.options[i];
    if (this.autofillZipcodes.selectedOption !== selectionItemsQuantity)
      this.autofillZipcodes.selectedOption += 1;
    if (this.autofillZipcodes.selectedOption === selectionItemsQuantity)
      this.autofillZipcodes.selectedOption = 0;
  }

  @Mutation
  navigateCitiesUp() {
    const selectionItemsQuantity = this.autofillCities.options.length - 1;
    if (this.autofillCities.selectedOption > -1)
      this.autofillCities.selectedOption -= 1;
    if (this.autofillCities.selectedOption === -1)
      this.autofillCities.selectedOption = selectionItemsQuantity;
    const i = this.autofillCities.selectedOption;
    this.autofillCities.keySelection = this.autofillCities.options[i];
  }

  @Mutation
  navigateCitiesDown() {
    const selectionItemsQuantity = this.autofillCities.options.length;
    const i = this.autofillCities.selectedOption;
    this.autofillCities.keySelection = this.autofillCities.options[i];
    if (this.autofillCities.selectedOption !== selectionItemsQuantity)
      this.autofillCities.selectedOption += 1;
    if (this.autofillCities.selectedOption === selectionItemsQuantity)
      this.autofillCities.selectedOption = 0;
  }

  @Mutation
  getCities(payload: {
    result: {
      BUNDESLAND_NAME: string;
      GEMEINDE_NAME: string;
      KREIS_NAME: string;
      POSTLEITZAHL: string;
    }[];
    city: string;
  }) {
    this.autofillCities.options = [];
    // if there is only a city, the value will be automatic.
    if (payload.result.length === 1) {
      this.cityValue = payload.result[0].GEMEINDE_NAME;
      this.disable = true;
    }

    if (payload.result.length !== 0) {
      this.autofillCities.options = payload.result
        .filter((option) =>
          option.GEMEINDE_NAME.toLowerCase().includes(
            payload.city.toLowerCase(),
          ),
        )
        .map((option) => {
          return { city: option.GEMEINDE_NAME, disable: false };
        })
        .sort((a, b) => a.city.localeCompare(b.city, "de"));
      if (this.autofillCities.options.length === 0)
        this.supportCityMessage = "falscher Wohnort";
      else this.supportCityMessage = "";
    }
  }

  @Action
  async preFetchAvailableZipCodes(zipcode: string) {
    if (zipcode) {
      try {
        const result = await axios.get(
          `${process.env.BACKBONE_ESYOIL}${process.env.GET_ZIPCODES}/${zipcode}`,
        );
        this.context.commit("getZipCodes", result.data);
      } catch (error) {
        console.error(error.response.data);
      }
    }
  }

  @Action
  async preFetchAvailableCitiesByZipCodes(payload: {
    zipcode: string;
    city: string;
  }) {
    if (payload.zipcode) {
      try {
        const result = await axios.get(
          `${process.env.BACKBONE_ESYOIL}${process.env.ESYOIL_PLACES}/${payload.zipcode}`,
        );
        this.context.commit("getCities", {
          result: result.data,
          city: payload.city,
        });
      } catch (error) {
        console.error(error.response.data);
      }
    }
  }

  /*@Action
  preFetchStreetNames(payload: { zipcode: string; streetName: string }) {
    axios({
      method: "get",
      url: `${process.env.BACKBONE_ESYOIL}${process.env.STREET_NAMES}/${payload.zipcode}/${payload.streetName}`,
    })
      .then((result) => {
        this.context.commit("pushAutoCompletionStreetNames", result.data);
      })
      .catch((error) => {
        console.error(error.response.data);
      });
  }*/

  @Action
  fetchRatePayOptions(ratePayData: {
    price: number;
    preselectionIndex: number;
  }) {
    axios({
      method: "get",
      url: `${process.env.BACKBONE_ESYOIL}${process.env.INSTALLMENT_PLANS}/${ratePayData.price}`,
    })
      .then((result) => {
        this.context.commit("pushRatePayOptions", result.data);
        this.context.commit(
          "setratepayData",
          result.data[ratePayData.preselectionIndex],
        );
      })
      .catch((error) => {
        console.error(error.response.data);
      });
  }

  @Mutation
  trimSpacesInAllPhoneNumber() {
    const data = this.heatingOilOrderData.heatingOilOrder;
    data.customer.phone = data.customer.phone.replace(/\s/g, "");
    data.customer.mobile = data.customer.mobile.replace(/\s/g, "");

    if (data.customer.billingAddress) {
      data.customer.billingAddress.phone =
        data.customer.billingAddress.phone.replace(/\s/g, "");
    }

    if (data.customer.otherUnloadingPoints) {
      data.customer.otherUnloadingPoints =
        data.customer.otherUnloadingPoints.map((e) => {
          e.phone = e.phone.replace(/\s/g, "");
          return e;
        });
    }
  }

  @Action
  async sendPurchaseData() {
    this.context.commit("trimSpacesInAllPhoneNumber");

    try {
      const result = await axios({
        method: "post",
        url: `${process.env.BACKBONE_ESYOIL}${process.env.ORDER_PLACEMENT}`,
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
          "X-Validation-Token": this.context.getters.authenticationToken,
        },
        data: {
          ...this.context.getters.heatingOilOrder,
          fingerprint: this.context.getters.paylaToken,
        },
      });
      await this.context.commit("setOrderStatusSuccess", result.data);
    } catch (error) {
      console.error(error.response.data);
      this.context.commit("setGlobalErrorMessage", error.response.data.message);
      this.context.commit("setOrderStatusSuccess", error.response.data);
    }
  }

  @Mutation
  resetCheckoutStorage() {
    this.headerSetup = JSON.parse(JSON.stringify(headerSetupDefault));
    this.heatingOilOrderData = JSON.parse(
      JSON.stringify(heatingOilOrderDataDefault),
    );
    // Workaround for Safari bug: Resetting the price value to 0
    // when navigating back from the checkout page to the price comparison overview
    this.heatingOilOrderData.selection.bruttoPrice.price = 0;
  }
}

import axios, { AxiosResponse } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  PriceComparingBetweenDatesResult,
  PriceComparingHighestAndLowest,
  PriceComparingTransformedData,
} from "~/types/HeatingOilCalculator";

@Module({
  name: "priceComparison",
  namespaced: true,
  stateFactory: true,
})
export default class PriceComparison extends VuexModule {
  public historicalHighestAndLowestPrice: PriceComparingHighestAndLowest | null =
    null;
  public historyComparePrices: PriceComparingBetweenDatesResult | null = null;
  public historyComparePricesTransformed:
    | PriceComparingTransformedData[]
    | null = null;

  @Mutation
  setHistoryComparePrices(data: PriceComparingBetweenDatesResult | null) {
    this.historyComparePrices = data;
    this.historyComparePricesTransformed = data ? transformData(data) : null;
  }

  @Mutation
  setHistoricalHighestAndLowestPrice(
    data: PriceComparingHighestAndLowest | null,
  ) {
    this.historicalHighestAndLowestPrice = data;
  }

  @Action
  async fetchHistoryPriceDiffData(zipcode: string) {
    this.context.commit("setHistoryComparePrices", null);
    try {
      const result: AxiosResponse<PriceComparingBetweenDatesResult> =
        await axios.get(
          `${process.env.BACKBONE_ESYOIL}${process.env.PRICE_HISTORY_COMPARE}/${zipcode}`,
        );
      if (result.data) {
        this.context.commit("setHistoryComparePrices", result.data);
      }
    } catch (err) {
      this.context.commit("setHistoryComparePrices", null);
      return false;
    }
  }

  @Action
  async fetchHistoricalHighestAndLowestPrice(zipcode: string) {
    this.context.commit("setHistoricalHighestAndLowestPrice", null);
    try {
      const result: AxiosResponse<PriceComparingHighestAndLowest> =
        await axios.get(
          `${process.env.BACKBONE_ESYOIL}${process.env.PRICE_HISTORY_HIGH_AND_LOW}/${zipcode}`,
        );
      if (result.data) {
        this.context.commit("setHistoricalHighestAndLowestPrice", result.data);
      }
    } catch (err) {
      this.context.commit("setHistoricalHighestAndLowestPrice", null);
      return false;
    }
  }
}

function transformData(
  data: PriceComparingBetweenDatesResult,
): PriceComparingTransformedData[] {
  const liters = [1000, 4000, 5000, 8000, 10000];
  const transformed: PriceComparingTransformedData[] = [];

  for (const liter of liters) {
    const name = `${liter} Liter`;
    const todayValue = Number(data.today[`his_${liter}`]);
    const yesterdayValue = Number(data.yesterday[`his_${liter}`]);
    const trend = todayValue - yesterdayValue;

    transformed.push({ name, todayValue, yesterdayValue, trend });
  }

  return transformed;
}

// Libraries
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import axios from "axios";

@Module({
  name: "historydata",
  namespaced: true,
  stateFactory: true,
})
export default class HistoryDataStore extends VuexModule {
  historyPriceData = [];

  @Mutation
  setHistoryPriceData(historyPrices: Array<{ price: string; date: string }>) {
    this.historyPriceData = historyPrices;
  }
  @Action
  async fetchHistoryPriceData(formData: { zipcode: string; date: string }) {
    try {
      const result = await axios({
        method: "get",
        url: `${process.env.API_ESYOIL}${process.env.CALC_HISTORY_PRICING}/${formData.zipcode}/${formData.date}`,
        headers: {
          accept: "*/*",
        },
      });
      this.context.commit("setHistoryPriceData", result.data.data);
    } catch (error) {
      console.error(error.response.data);
    }
  }
}
